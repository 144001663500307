import { ProductsRepoCustomerSpecificPricingQuery } from '@/graphql/generated/graphql';
import { CostSummary, CustomerVariantCostSummary, VariantForPLP } from '../types/productTypes';
import { ConsumerTypeMoneyField } from '@/types/consumerTypes';

export const updateCostSummaries = (products: VariantForPLP[], lowestCostSummaries: CustomerVariantCostSummary[], moneyField: ConsumerTypeMoneyField) => {
  const productsWithPricing = products.map((product) => {
    const pricing = lowestCostSummaries.find(({ id }) => id === product.variantId);

    if (!pricing?.lowestCostSummary) {
      return product;
    }

    const lowestMonthlyCost = pricing.lowestCostSummary.recurring.totalAmount[moneyField].value;
    return {
      ...product,
      lowestMonthlyCost,
    };
  });

  return productsWithPricing;
};

type CostSummaryRiskBasedPricingSubset = NonNullable<ProductsRepoCustomerSpecificPricingQuery['variants'][number]['costSummaries']>[number];

export const updateAllVariantsCostSummaries = (
  costSummaries: CostSummary[],
  riskBasedCostSummaries: CostSummaryRiskBasedPricingSubset[]
) => {
  return costSummaries.map((costSummary) => {
    const riskBasedCostSummary = riskBasedCostSummaries.find(riskBasedSummary => riskBasedSummary.id === costSummary.id);
    const newTotalAmountAfterTax = riskBasedCostSummary?.recurring.totalAmount.valueAfterTax.value;
    const newTotalAmountBeforeTax = riskBasedCostSummary?.recurring.totalAmount.valueBeforeTax.value;

    if (!newTotalAmountAfterTax || !newTotalAmountBeforeTax) return costSummary;

    return {
      ...costSummary,
      recurring: {
        ...costSummary.recurring,
        totalAmount: {
          ...costSummary.recurring.totalAmount,
          valueAfterTax: {
            ...costSummary.recurring.totalAmount.valueAfterTax,
            value: newTotalAmountAfterTax,
          },
          valueBeforeTax: {
            ...costSummary.recurring.totalAmount.valueBeforeTax,
            value: newTotalAmountBeforeTax,
          },
        },
      },
    };
  });
};
