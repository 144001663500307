import { primaryColors, secondaryColors } from '../../styles/variables/colours';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL, BREAKPOINTS } from '@raylo-tech/raylopay-ui';

export const StyledBreadcrumbs = styled.div<{ $position: 'fixed' | 'absolute' }>`
  ${({ $position }) => `
    width: 100%;
    background-color: ${primaryColors.b01};
    padding: ${px2Rem(8)} ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)};
    display: flex;
    align-items: center;
    overflow-x: scroll;
    position: ${$position};
    top: ${$position === 'fixed' ? px2Rem(151) : '0'};
    transition: top 0.8s ease;
    z-index: ${$position === 'fixed' ? '98' : '0'};

    @media (min-width: 333px) {
      top: ${$position === 'fixed' ? px2Rem(134) : '0'};
    }

    @media (min-width: 560px) {
      top: ${$position === 'fixed' ? px2Rem(117) : '0'};
    }

    @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
      top: ${$position === 'fixed' ? px2Rem(165) : '0'};
    }

    a {
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
      cursor: pointer;
      color: ${primaryColors.w01};
      text-decoration: none;
      display: flex;
      align-items: center;
      text-wrap: nowrap;

      &:hover {
        text-decoration: underline;
        color: ${secondaryColors.b04};
      }

      &::after {
        content: '';
        background-image: url("${ASSETS_SVGS_BASE_URL}/chevron-right-white.svg");
        background-repeat: no-repeat;
        background-size: ${px2Rem(12)};
        height: ${px2Rem(12)};
        width: ${px2Rem(12)};
        display: inline-block;
        padding: 0 ${px2Rem(8)};
        background-position: center;
      }

      &.active {
        color: ${primaryColors.p01};
        pointer-events: none;

        &::after {
          display: none;
        }
      }
    }
  `};
`;
