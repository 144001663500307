import { ReactNode } from 'react';
import Icon from '../../elements/Icon';
import Container from '../../elements/Container';
import { px2Rem } from '../../utils/px2Rem';
import { Copy } from '@raylo-tech/raylopay-ui';
import { secondaryColors } from '../../styles/variables/colours';
import { StyledPreApprovedBadgeInline } from './styles';

/**
 * @deprecated This component has been replaced with the `<Badge />` component in `@raylo-tech/raylopay-ui`.
 */

const PreApprovedBadge = () => (
  <StyledPreApprovedBadgeInline>
    <Container
      flexRow
      backgroundColor={secondaryColors.b02}
      dataTestId="pre-approved-badge"
    >
      <Icon name="PreApproved" size={px2Rem(17)} dataTestId="pre-approved-icon" />
      <Copy margin={`0 0 0 ${px2Rem(4)}`} letterSpacing={1}>
        PRE-APPROVED
      </Copy>
    </Container>
  </StyledPreApprovedBadgeInline>
);

export default PreApprovedBadge;
